import { useCallback } from 'react'
import { AvatarSize } from '~/core/ui/Avatar'

type FCC<Props = Record<string, unknown>> = React.FC<
  React.PropsWithChildren<Props>
>

type AvatarHandlerProps = {
  id: number
  size?: AvatarSize
  isShow?: boolean
  removeAvatar?: (id: number) => void
}

const REMOVE_ICON_SIZE: { [key: string]: number } = {
  '2xs': 10,
  xs: 12,
  sm: 14,
  md: 16,
  lg: 18
}

const RemoveIcon = ({ size = 'md' }: { size?: AvatarSize }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={REMOVE_ICON_SIZE[size] || 16}
    height={REMOVE_ICON_SIZE[size] || 16}
    viewBox="0 0 16 16"
    fill="none">
    <g>
      <circle id="Ellipse 163" cx="8" cy="8" r="8" fill="white" />
      <g id="Icon filled/X circle">
        <path
          id="Union"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.0001 14.4001C11.5347 14.4001 14.4001 11.5347 14.4001 8.0001C14.4001 4.46548 11.5347 1.6001 8.0001 1.6001C4.46548 1.6001 1.6001 4.46548 1.6001 8.0001C1.6001 11.5347 4.46548 14.4001 8.0001 14.4001ZM6.96578 5.83441C6.65336 5.52199 6.14683 5.52199 5.83441 5.83441C5.52199 6.14683 5.52199 6.65336 5.83441 6.96578L6.86873 8.0001L5.83441 9.03441C5.52199 9.34683 5.52199 9.85336 5.83441 10.1658C6.14683 10.4782 6.65336 10.4782 6.96578 10.1658L8.0001 9.13147L9.03441 10.1658C9.34683 10.4782 9.85336 10.4782 10.1658 10.1658C10.4782 9.85336 10.4782 9.34683 10.1658 9.03441L9.13147 8.0001L10.1658 6.96578C10.4782 6.65336 10.4782 6.14683 10.1658 5.83441C9.85336 5.52199 9.34683 5.52199 9.03441 5.83441L8.0001 6.86873L6.96578 5.83441Z"
          fill="#9CA3AF"
        />
      </g>
    </g>
  </svg>
)

const AvatarHandler: FCC<AvatarHandlerProps> = ({
  children,
  size,
  isShow = false,
  id,
  removeAvatar
}) => {
  const onRemoveAvatar = useCallback(() => {
    removeAvatar && removeAvatar(id)
  }, [id, removeAvatar])

  return !isShow ? (
    <div>{children}</div>
  ) : (
    <div className={`group relative`}>
      <div
        {...(removeAvatar ? { onClick: onRemoveAvatar } : {})}
        className="-bottom-0.5 -right-0.5 z-10 hidden cursor-pointer group-hover:absolute group-hover:inline-block">
        <RemoveIcon size={size} />
      </div>
      {children}
    </div>
  )
}

AvatarHandler.displayName = 'AvatarGroup'

export { AvatarHandler }
