'use client'

import { forwardRef } from 'react'
import { Select, SelectProps } from '~/core/ui/Select'

const MultipleSelect = forwardRef<HTMLSelectElement, SelectProps>(
  (props, ref) => {
    return (
      <Select
        ref={ref}
        {...props}
        isMulti={true}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
      />
    )
  }
)

MultipleSelect.displayName = 'MultipleSelect'

export { MultipleSelect }
