'use client'

import { FC, useCallback, useEffect, useRef, useState } from 'react'
import {
  ActionMeta,
  GroupBase,
  MultiValue,
  OptionsOrGroups,
  SingleValue
} from 'react-select'
import configuration from '~/configuration'
import { MultipleSelect } from '~/core/ui/MultipleSelect'
import {
  IPromiseSearchOption,
  ISelectOption,
  SelectClassControl,
  SelectOptionProps, SelectProps, SelectSizeProps
} from '~/core/ui/Select'
import { removeAccents } from './utils'

interface AsyncMultipleSearchWithSelectProps {
  promiseOptions?: (
    params: IPromiseSearchOption
  ) => Promise<{ metadata?: { totalCount: number }; collection: never[] }>
  value?: ISelectOption | Array<ISelectOption>
  onChange: (
    newValue: SingleValue<ISelectOption> | MultiValue<ISelectOption> | null,
    actionMeta: ActionMeta<ISelectOption>
  ) => void
  size?: SelectSizeProps
  placeholder?: string
  isMultiShowType?: 'default' | 'ava-leading' | 'dot-leading' | 'icon-leading'
  isMultiComponent?: 'InputChips' | 'SuggestionChipsItem'
  configSelectOption?: SelectOptionProps
  destructive?: boolean
  className?: string
  classNameOverride?: SelectClassControl
  menuPlacement?: 'top' | 'auto' | 'bottom'
  // for outline search which the menu alway open
  loadAsyncWhenRender?: boolean
  loadAsyncWhenOpen?: boolean
  detectAvatarFromOption?: boolean
  creatable?: boolean
  isDropdown?: boolean
  showDropdownIndicator?: boolean
  cacheOptions?: string
  isSearchable?: boolean
  isDisabled?: boolean
  extraItem?: ISelectOption
  onInputChange?: SelectProps['onInputChange']
  isValidNewOption?: SelectProps['isValidNewOption']
  callbackClearSearchData?: () => void
  isClearable?: boolean
  mappingGroupData?: (
    data?: ISelectOption[]
  ) => OptionsOrGroups<ISelectOption, GroupBase<ISelectOption>>
  forceUpdatePromiseOptions?: boolean
}

const AsyncMultipleSearchWithSelect: FC<AsyncMultipleSearchWithSelectProps> = ({
  promiseOptions,
  value,
  onChange,
  placeholder = 'Select',
  size = 'md',
  loadAsyncWhenRender,
  loadAsyncWhenOpen = true,
  detectAvatarFromOption,
  creatable = false,
  isSearchable = true,
  extraItem,
  mappingGroupData,
  callbackClearSearchData,
  forceUpdatePromiseOptions,
  ...props
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [isSearch, setSearch] = useState(false)
  const [searchInput, setSearchInput] = useState<string | undefined>()
  const [options, setOptions] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [pageState, setPage] = useState(1)
  const [totalCount, setTotalCount] = useState<number>(configuration.defaultPageSize)  
  const timeoutRef = useRef<NodeJS.Timeout>()

  const handleFetch = useCallback(async ({
    page = 1,
    mergedData = false,
    search = ''
  }: {
    page: number
    mergedData: boolean
    search?: string
  }) => {
    if (page !== pageState) {
      setPage(page)
    }

    if (search === '') {
      setSearch(false)
    }
   
    const mappingOptions = mappingGroupData
      ? options.map((item: { options: ISelectOption[] }) => item.options).flat()
      : options


    if (promiseOptions && (!mergedData || totalCount > mappingOptions.filter(item => !item?.parentId).length)) {
      setLoading(true)
      const response = await promiseOptions({
        search: search || '',
        limit: configuration.defaultPageSize,
        page
      })
      setLoading(false)
      setTotalCount(response.metadata?.totalCount || 0)
      if (!mappingGroupData) {
        const cloneData = [...options, ...response.collection]
        const optionsMerge = handle4ExtraItem(
          mergedData ? cloneData : response.collection
        )
        setOptions(optionsMerge as any)
      } else {
        const cloneData = [
          ...(options.map((item: { options: never[] }) => item.options).flat() || []),
          ...response.collection
        ]
        const optionsMerge = handle4ExtraItem(
          mergedData ? cloneData : response.collection
        )
        const groupingResponseData = mappingGroupData(optionsMerge)

        const mergeGrouping = groupingResponseData.reduce(
          (result, resGroup) => {
            const indexGroupLabel = options.findIndex(
              (groupOption: { label: string }) =>
                groupOption?.label === resGroup?.label
            )

            const mergeOptions =
              indexGroupLabel >= 0
                ? {
                    label: (options as any)[indexGroupLabel]?.label,
                    options: [
                      ...(resGroup as GroupBase<ISelectOption>).options
                    ]
                  }
                : resGroup
            return [...result, mergeOptions]
          },
          [] as any
        )
        setOptions(mergeGrouping as any)
      }
    }

    if (menuIsOpen === false) {
      setTimeout(() => {
        setMenuIsOpen(true)
      }, 0);
    }
  }, [pageState, options, totalCount, forceUpdatePromiseOptions])

  const clearSearchData = useCallback(() => {
    setPage(1)
    setTotalCount(configuration.defaultPageSize)
    setOptions([])
    callbackClearSearchData && callbackClearSearchData()
  }, [])

  const handle4ExtraItem = (listOptions: never[]) => {
    if (extraItem) {
      let findIndexItem = listOptions.findIndex(
        (option: { value: string }) => option.value === extraItem.value
      )
      let cloneData = [...listOptions]
      if (findIndexItem !== -1) {
        cloneData[findIndexItem] = extraItem as never
      } else {
        cloneData = [extraItem as never, ...listOptions]
      }
      return cloneData
    }
    return listOptions
  }

  useEffect(() => {
    if (loadAsyncWhenRender) {
      handleFetch({ page: 1, mergedData: false })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isSearch)
      handleFetch({
        page: 1,
        mergedData: false,
        search: searchInput
      })
  }, [searchInput, isSearch])

  return (
    <MultipleSelect
      cacheOptions={false}
      isLoading={isLoading}
      isSearchable={isSearchable}
      options={options}
      menuIsOpen={menuIsOpen}
      onMenuOpen={() => {
        if (loadAsyncWhenOpen) {
          handleFetch({ page: pageState, mergedData: false })
        }
      }}
      onMenuClose={() => {
        if (menuIsOpen) {
          setMenuIsOpen(false)
          setSearch(false)
          setSearchInput(undefined)
          if (!props.isDropdown) {
            clearSearchData()
          }
        }
      }}
      onMenuScrollToBottom={() => {
        handleFetch({ page: pageState + 1, mergedData: true, search: isSearch ? searchInput : '' })
      }}
      size={size}
      creatable={creatable}
      placeholder={placeholder}
      onChange={onChange}
      value={value || []}
      {...props}
      async={false}
      onInputChange={(input, actionMeta) => {
        if (actionMeta && actionMeta.action === 'input-change') {
          if (!!timeoutRef?.current) clearTimeout(timeoutRef.current)

          timeoutRef.current = setTimeout(() => {
            setSearchInput(input)
            setSearch(true)
          }, 500)
        }

        if (actionMeta.action === 'input-change' && props.onInputChange) {
          props.onInputChange(input, actionMeta)
        }
      }}
      isValidNewOption={props?.isValidNewOption}
      filterOption={() => true}
      configSelectOption={{
        ...props.configSelectOption,
        ...(detectAvatarFromOption
          ? {
              avatar: options.find((item) =>
                Object.keys(item).includes('avatar')
              )
            }
          : {})
      }}
    />
  )
}

AsyncMultipleSearchWithSelect.displayName = 'AsyncMultipleSearchWithSelect'

export { AsyncMultipleSearchWithSelect }
export type { AsyncMultipleSearchWithSelectProps }

