import { useEffect, useState } from 'react'
import useBoundStore from '~/lib/store'

interface IProps {
  kind: string
}

const useDetectCompanyWithKind = (props: IProps) => {
  const { kind } = props
  const { user } = useBoundStore()
  const [state, setState] = useState({
    url: '',
    isLoaded: false
  })

  useEffect(() => {
    if (user?.currentTenant) {
      if (user.currentTenant?.companyKind === kind) {
        setState({
          ...state,
          url: `/${kind}`,
          isLoaded: true
        })
      } else {
        setState({
          ...state,
          isLoaded: true
        })
      }
    }
  }, [user])

  return {
    ...state,
    isCompanyKind: user?.currentTenant?.companyKind === kind
  }
}

export default useDetectCompanyWithKind
